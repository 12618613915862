.about-top-background {
    background-color: #4c718f;
}

.about-us-title {
    font-weight: bold;
    text-align: center;
    line-height: 1.2;
    padding-bottom: 20px;
    font-family: 'Lobster', 'Arial Narrow', Arial, sans-serif;
    font-size: 60px;
    background: -webkit-linear-gradient(#eee, #333);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    filter: drop-shadow(2px 2px #333);
}

.about-us-section-title {
    text-align: center;
    font-family: 'Lobster';
    padding-top: 30px;
    padding-bottom: 15px;
}

.about-us-description {
    text-align: center;
    font-size: 20px;
    padding-left: 15%;
    padding-right: 15%;
}

.allMissionStuff {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.mission {
    padding-left: 2rem;
    font-size: 1.75rem;
    padding-top: 15px;
    padding-bottom: 15px;
    font-style: italic;
    line-height: 1.2;
}

.arrow_box {
	position: relative;
    background: #304759;
    max-width: 100%;
    color: white;
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
}
.arrow_box:after, .arrow_box:before {
	left: 100%;
	top: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
    pointer-events: none;
}

.arrow_box:after {
	border-color: rgba(136, 183, 213, 0);
	border-left-color: #304759;
    border-width: 48px;
	margin-top: -48px;
}

.arrow_box:before {
	border-color: rgba(194, 225, 245, 0);
	border-left-color: #304759;
    border-width: 48px;
    margin-top: -48px;
}

@media (max-width: 767px) {
    .arrow_box:before {
        border-width: 0px;
    }
    .arrow_box:after {
        border-width: 0px;
    }
    .arrow_box {
        max-width: 100%;
    }
    .about-us-description {
        padding-left: 0px;
        padding-right: 0px;
    }
}