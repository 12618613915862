.customer-review-section {
    text-align: center;
}

.thumbs-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.like-dislike {
    margin: 10px;
    padding: 15px;
    border-style: solid;
    border-radius: 50%;
    border-width: 2px;
    border-color: lightgray;
}

.like-dislike:Hover{
    background-color: #79AAF7;;
    cursor: pointer;
}

.like-dislike-clicked {
    background-color: #79AAF7;;
}

.like-dislike-clicked:hover {
    background-color: #79AAF7;
}