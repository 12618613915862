.short-review {
    font-size: 25px;
    font-style: italic;
}

.main-review {
    font-size: 20px;
}

.review-section-bar {
    height: 2px;
    width: 100%;
    background-color: white;
    margin-top: 10px;
    margin-bottom: 10px;
}

.review-section-title {
    text-align: center;
    font-size: 20px;
}

.review-decision {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 10px;
}

.review-decision-icon {
    height: 30px;
    width: 30px;
}

.review-decision-text {
    font-weight: bold;
    font-size: 25px;
    padding: 5px;
}

.review-title {
    text-align: center;
    padding-bottom: 20px;
}