.wrapper-40 {
    margin-left: 40px;
    margin-right: 40px;
}

.prompt-header {
    margin-bottom: 20px;
}

.prompt-container {
    background-color: #4c718f;
    color: black;
    padding: 20px;
    border-radius: 25px;
    margin: 5px;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    width: 100%;
}

.prompt-container:hover {
    cursor: pointer;
    color: black;
    box-shadow: 1px 1px 6px #1f8aff; 
}

.submission-container {
    background-color: #4c718f;
    color: black;
    padding: 20px;
    border-radius: 25px;
    margin: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    width: 100%;
}

.submission-container:hover {
    color: black;
    box-shadow: 1px 1px 6px #1f8aff; 
}

.prompt-title {
    font-size: 25px;
    font-weight: bold;
    padding-bottom: 10px;
}

@media only screen and (max-width: 1200px) {
    .wrapper-40 {
        margin-left: 0px;
        margin-right: 0px;
    }
}

.add-story-button {
    margin-bottom: 20px;
    min-width: 200px !important;
}