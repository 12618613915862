.hamburger-section {
    padding-top: 30px;
    padding-left: 20px;
    background-color: #233340;
    font-size: 20px;
    overflow-y: scroll !important;
}

.mobile-nav-header {
    color: gray;
    padding: 10px;
}

.mobile-nav-link {
    color: white;
    padding: 10px;
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
    width: 100%;
}

.mobile-nav-link-donate {
    color: #1091FF !important;
    padding: 10px;
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
    width: 100%;
}

.mobile-nav-link a {
    color: white !important;
}

.pad-30-l {
    padding-left: 30px;
}

.no-padding {
    padding: 0px !important;
}