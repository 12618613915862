.publication-card-container {
    cursor: pointer;
    margin: 5px;
}

.publication-title {
    text-align: center;
    padding-top: 5px;
    font-size: 16px;
}

.publication-image {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}