.liked-profile {
    padding: 15px;
    padding-left: 0px;
    display: flex;
    flex-direction: row;
}

.liked-profile-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-left: 15px;
    line-height: 1.4rem;
}

.liked-profile-names {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.liked-profile-fullname {
    font-weight: bold;
}

.liked-profile-username {
    color: gray;
}

.liked-profile-description {
    font-size: 1rem;
    font-family:'Segoe UI';

    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 1.2rem; /* fallback */
    max-height: 3.6rem; /* fallback */
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
}

.view-profile-button {
    padding: 8px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    font-size: 12px;
}

@media only screen and (max-width: 414px) {
    .likes-modal {
        padding-right: 0px !important;
    }
}