.reactions-mobile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.reaction-row-mobile {
    display: flex;
    flex-direction: row;
}

.reaction-icon {
    height: 40px;
    width: 40px;
    margin-right: 8px;
}

.reaction-icon-highlighted {
    height: 40px;
    width: 40px;
    margin-right: 8px;
    background-color: #1091ff;
}

.reaction-text {
    padding-top: 8px;
    text-decoration: underline;
}

.message-text {
    padding-top: 10px;
    padding-bottom: 10px;
}