.google-login-button {
    background-color: #3395ff !important;
}
.google-login-button:hover {
    background-color: #1f8aff !important;
}

.google-login-img {
    width: 50px;
    margin-right: 20px;
}

.or-line-divider-container {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}
.or-line-divider {
    width: 100%;
    height: 1.5px;
    margin-top: 10px;
    background-color: gray;
}
.or-text {
    margin-right: 5px;
    margin-left: 5px;
    color: gray;
}

.website-title-modal {
    /* font-size: 60px;
    text-align: center;
    line-height: 1.2;
    background: -webkit-linear-gradient(#eee, #333);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding:20px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    text-shadow: 2px 2px #333; */
    font-weight: bold;
    text-align: center;
    line-height: 1.2;
    padding-top:20px;
    padding-bottom: 20px;
    font-family: 'Lobster', 'Arial Narrow', Arial, sans-serif;
    font-size: 50px;
    background: -webkit-linear-gradient(#eee, #333);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    filter: drop-shadow(2px 2px #333);
  }

  .account-modal-container {
      z-index: 9999;
  }

  .center-content {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;
  }

  .please-sign-up {
      font-size: 30px;
      font-weight: bold;
      font-family:'Segoe UI';
      line-height: 1.2;
  }

  .short-description {
      max-width: 500px;
      font-family:'Segoe UI';
      font-size: 20px;
      padding-bottom: 20px;
  }

  .short-description a {
      color: blue;
  }

  .short-description-bigger {
    max-width: 600px;
    font-family:'Segoe UI';
    font-size: 30px;
    padding-bottom: 20px;
    line-height: 1.2;
  }

  .highlight:hover {
      cursor: pointer;
  }

  .sign-up-form {
      width: 500px;
  }

  .bottom-modal-buttons {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
  }

  .pad-right-10 {
      margin-right: 10px;
  }

  .error-message {
      color: red;
      display: none;
  }

  .new-image-input {
      padding-top: 20px;
  }

  .image-uploaded-success {
      display: none;
      padding-top: 15px;
  }

  .is-16 {
      margin-left: 15px !important;
      margin-bottom: 0px !important;
      width: 20px;
      height: 20px;
  }

  .email-list-popup-form {
      max-width: 500px;
  }

  .cookie-notice {
    max-width: 500px;
    font-family:'Segoe UI';
    font-size: 14px;
    padding-bottom: 20px;
    line-height: 1;
  }