.category-title-container {
    position: relative;
  text-align: center;
  color: white;
  margin-bottom: 10px;
  width: 100%;
}

.category-title {
    position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.no-padding {
    padding: 0px !important;
}

.sort-by-row {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.sort-by-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.sort-by {
  font-size: 25px;
  font-weight: bold;
  margin-top: 10px;
  margin-right: 5px;
}

.sorting-dropdown {
  margin-left: 5px;
  background-color: #233340 !important;
  font-size: 20px;
  color: white;
  padding: 5px;
  border-color: #79AAF7;
  border-width: 3px;
  border-radius: 2px;
}
.sorting-dropdown:hover {
  cursor: pointer;
}