.loading-spinner {
    width: 3rem !important;
    height: 3rem !important;
    margin-top: 50px;
}

/* .not-logged-in-popup {
    height: 150px;
    width: 100%;
    text-align: center;
    line-height: 70px;
    background: #FF5555;
    color: #fff;
    border-radius: 50px;
    position: fixed;
    z-index: 999;
    bottom: 0%;
    cursor: pointer;
} */

.user-writing-prompt {
    padding-top: 20px;
    padding-bottom: 20px;
}