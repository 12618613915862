@import url(https://fonts.googleapis.com/css?family=Lobster);

.story-col {
  padding: 0px !important;
}

.writer-col {
  padding: 0px !important;
  height: 100%;
}

.straight-line {
  background-color: white !important;
  height: 2px;
  width: 100%;
  margin-bottom: 20px;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.wrap-me {
  flex-wrap: wrap;
}

.blue-background {
  background-color: #79AAF7 !important;
}

.space-5 {
  padding-bottom: 5px;
}

.tag-icon {
  height: 25px;
  width: 25px;
}

.full-story-image {
  width: 100%;
}

.sample-story-image {
  width: 100%;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  height: 175px;
}

.description-dropdown {
  background-color: #79AAF7 !important;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.tags-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.category-tag {
  padding-right: 10px;
}

.eye-icon {
  height: 25px;
  width: 25px;
  margin-top: 8px;
  margin-right: 5px;
}

.eye-icon-small {
  margin-top: 8px;
  height: 18px;
  width: 18px;
  margin-right: 3px;
}

/* START TITLE AND LOGO */

.website-title {
  /* font-size: 60px;
  text-align: center;
  line-height: 1.2;
  background: -webkit-linear-gradient(#eee, #333);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding:20px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  text-shadow: 2px 2px #333; */
  font-weight: bold;
  text-align: center;
  line-height: 1.2;
  padding-top:20px;
  padding-bottom: 20px;
  font-family: 'Lobster', 'Arial Narrow', Arial, sans-serif;
  font-size: 75px;
  background: -webkit-linear-gradient(#eee, #333);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  filter: drop-shadow(2px 2px #333);
}

.home-logo {
  width: 50px;
  height: 50px;
}

/* END TITLE AND LOGO */

/* START NAVBAR */
.menu-dropdown {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    font-size: 16px !important;
}

.navbar-button-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 5px;
  padding-bottom: 5px;
}

.pad-top-8 {
  padding-top: 8px !important;
}

.pad-bottom-10 {
  padding-bottom: 10px;
}

.make-sticky {
  position: fixed !important;
  top: 0 !important;
  z-index: 100;
  width: 100% !important;
}

.pen-icon {
  margin-top: 3px;
  height: 20px;
  width: 20px;
}

.small-user-icon {
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

.close-icon {
  margin-left: 20px;
  margin-top: 10px;
  height: 30px;
  width: 30px;
}

.close-icon:hover {
  cursor: pointer;
}

.search-icon {
  margin-left: 10px;
  height: 30px;
  width: 30px;
}

.search-icon:hover {
  cursor: pointer;
}

.search-container {
  padding-top: 20px;
  display: none;
}

.searched-story {
  padding: 20px;
}

.searched-story:hover {
  background-color: #4c718f;
  cursor: pointer;
}

.no-stories-match {
  padding: 20px;
}
 
.top-write-button {
  background-color: #79AAF7 !important;
  padding: 8px;
  margin-right: 10px;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-bottom: 5px;
}

.side-write-button {
  background-color: #79AAF7 !important;
  padding: 8px;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-bottom: 10px;
}

.top-write-button:hover {
  cursor: pointer;
}

.side-write-button:hover {
  cursor: pointer;
}

.top-button-text{
  padding-left: 10px;
  font-size: 16px;
  color: white;
}

.top-login-button {
  padding: 8px;
  margin-right: 10px;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-bottom: 5px;
}

.top-login-button:hover {
  cursor: pointer;
}

.notifications-icon {
  border-radius: 50%;
  padding-left: 8px;
  padding-right: 8px;
  margin-left: 3px;
  border-color: white;
  border-style: solid;
}

.num-notifications {
  color: white;
  font-size: 12px;
}


/* END NAVBAR */

.post_gallary_area {
    padding-top: 50px;
}

.image-quote-container {
  position: relative;
  top: 0;
  left: 0;
}

.home-background-image {
  position: relative;
  top: 0;
  left: 0;
}

/* START TOP STORIES */

.category-tag {
  display: flex;
  flex-direction: row;
}

.website-title-overlay {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.top-stories-overlay {
  position: absolute;
  top: 35%;
}

.top-stories-title {
  display:none !important;
  padding-top: 20px;
  font-size: 25px !important;
  text-align: center;
}

.top-stories-list {
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 20px;
}

.story-card-container {
  height: calc(100% - 10px);
  margin: 5px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.story-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #2a4052;
  color: white !important;
  /* padding: 20px; */
  border-radius: 25px;
  height: 100%;
  width: 100%;
  max-width: 375px; /* So that the image doesn't looked super stretched on really wide phones */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.story-card:hover {
  box-shadow: 0 0 4px #79AAF7; 
  cursor: pointer;
}

.story-card:hover .story-card-title {
  text-decoration: underline;
}

.story-card-title {
  font-weight: bold;
  font-size: 1.5rem;
  font-family:'Segoe UI';
  padding-top: 10px;
  padding-bottom: 10px;
}

.story-card-body {
  font-size: 1rem;
  font-family:'Segoe UI';
  /* margin-top: 10px;
  margin-bottom: 10px; */
  
  word-break: break-word;
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   line-height: 1.5rem; /* fallback */
   max-height: 7.5rem; /* fallback */
   -webkit-line-clamp: 5; /* number of lines to show */
   -webkit-box-orient: vertical;
}

.story-page-author-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.story-card-author-image {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.story-card-author-anon-image {
  width: 40px;
  height: 40px;
}

.story-card-author-name {
  padding-top: 5px;
  padding-left: 10px;
}

.story-views {
  padding-top: 8px;
  font-size: 22px;
}
.story-views-blue {
  padding-top: 8px;
  font-size: 22px;
  color: #79AAF7;
}

.story-views-small {
  font-size: 16px;
}

.story-views-small-blue {
  font-size: 16px;
  color: #79AAF7;
}

/*END TOP STORIES */

/* START RECENT STORIES */

.recent-stories-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* END RECENT STORIES */ 

/* START REVIEWS STYLES */

.review-book-image {
  width: 275px;
  height: 400px;
}

.pad-bottom-30 {
  padding-bottom: 30px;
}

/* END REVIEWS STYLES */

.dark-theme {
  height: 100% !important;
}

.follow-us-title {
  padding-right: 10px;
  margin-bottom: 0px !important;
}

.large-instagram {
  width: 35px;
    height: 35px;
    margin-bottom: 10px;
    margin-right: 15px;
}

.large-instagram:hover {
  cursor: pointer;
}

.social-media-box-container {
  padding: 20px;
  background-color: #29333C;
  margin-bottom: 20px;
}

.newsletter-signup-container {
  padding: 20px;
  background-color: #29333C;
  margin-bottom: 20px;
}

.full {
  width: 100%;
}

.email-signup-button {
  width: 100%;
}

.search-options {
  display: flex;
  flex-direction: row;
}

.search-options .option:hover {
  cursor: pointer;
}

.search-options .highlighted {
  color: #007bff;
}

.search-options .divider-dot {
  padding-left: 5px;
  padding-right: 5px;
}

@media only screen and (max-width: 414px) {
  .website-title-overlay {
    top: 6%;
  }
  .story-card-container {
    margin: 15px;
    min-height: 435px;
  }
}

@media only screen and (max-width: 576px) {
  .sample-story-image {
    height: 100%;
    max-height: 225px;
  }
}

@media only screen and (max-width: 1024px) {
  .website-title {
    padding-top: 0px;
  }
  .top-stories-title {
    display: block !important;
  }
  .top-stories-overlay {
    position: relative;
    top: 0;
    left: 0%;
  }
  .top-stories-list {
    padding: 0px;
  }
  .heading {
    text-align: center;
  }
  .widget-title {
    font-size: 25px !important;
  }
  .categories-title {
    padding-top: 30px;
  }
}