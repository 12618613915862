.category-button-overlay {
    position: absolute;
    top: 40%;
    z-index: 100;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.category-button {
    border: 1px solid #79AAF7;
    /* border-radius: 8px; */
    padding: 10px 15px 10px 15px;
    font-size: 22px;
    font-weight: bold;
    background-color: #17222B;
    color: white;
    box-shadow: 0 0 10px #79AAF7; 
}
.category-button:hover {
    color: white;
    background-color: #79AAF7; 
    cursor: pointer;
}