.submit-contact-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.submit-contact-button {
    background-color: #79AAF7 !important;
    padding: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 15px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-size: 20px;
}

.submit-contact-button:hover {
    cursor: pointer;
}