.story-preview-content {
    padding: 10px;
    padding-top: 5px;
}

.story-stats {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.story-stats div:not(:last-child) {
    margin-right: 12px;
}

.story-stats div {
    margin-top: 5px;
}

.story-tag {
    border: 2px solid #79AAF7;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 12px;
    height: 30px;
    font-weight: bold;
}

.story-tag-live {
    border: 2px solid #79AAF7;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 12px;
    height: 30px;
    font-weight: bold;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.story-tag-live:hover {
    background-color: #79AAF7;
    cursor: pointer;
}