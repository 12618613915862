.message-user-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #2a4052;
    padding: 10px;
}

.messages-page .image {
    width: 70px;
    height: 70px;
    border-radius: 50%;
}

.messages-page .names {
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.messages-page .full {
    font-size: 20px;
    font-weight: bold;
    color: white;
}

.messages-page .username {
    font-size: 16px;
    color: white;
}

.messages-page .together{
    display: flex;
    flex-direction: row;
}

.messages-page .prev-icon {
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.message-content {
    height: 400px;
    overflow-y: scroll;
}

.cur-user-message {
    background-color: #79AAF7;
    color: black;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    width:fit-content;
    border-radius: 10px;
}

.other-user-message {
    background-color: #233340;
    color: white;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: fit-content;
    border-radius: 10px;
}

.send-message {
    display: flex;
    flex-direction: row;
}

.send-message .send-message-button {
    margin-top: 10px;
    max-height: 40px;
}

.align-right {
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin-right: 10px;
}

.link-text {
    color: #1091FF;
    cursor: pointer;
    padding-bottom: 5px;
}

.center-vert {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.message-timestamp {
    font-size: 12px;
    font-style: italic;
    text-align: center;
}



/* Messages List */
.messages-header-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.messages-header-bar .small-plus {
    width: 20px;
    height: 20px;
    margin-left: 10px;
}

.weak-line {
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: grey;
    height: 1px;
    width: 100%;
}

.message-preview {
    background-color: #233340;
    border-radius: 15px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    margin-bottom: 10px;
}

.message-preview:hover {
    background-color: #4d7ba1;
}

.new-message-header {
    width: 100%;
}

.pointer {
    cursor: pointer;
}

.send-first-message {
    text-align: center;
    padding-top: 20px;
}