.submit-form-container {
    border-style: solid; 
    border-width: 1px; 
    padding: 10px; 
    margin-left: 20%; 
    margin-right: 20%; 
    margin-top: 50px; 
    padding-bottom: 25px;
    border-color: lightgray; 
    border-radius: 2px;
}

@media (max-width: 800px) {
    .submit-form-container {
        margin-left: 5px; 
        margin-right: 5px; 
    }
}